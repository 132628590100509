import React, { useEffect, useRef, useState } from 'react'
import LocomotiveScroll from 'locomotive-scroll'
import s from './SemanticAnalyze.module.css'
import { preloadImages } from '../../../components/preloadImages'
import semanalyze from '../../../images/semanalyze2.jpg'
import macbook from '../../../images/macbook.png'
import steps from '../../../images/steps.png'
import screen from '../../../images/screen_semanalyze.jpg'
import phone1 from '../../../images/mobile1.png'
import phone2 from '../../../images/mobile2.png'
import phone3 from '../../../images/mobile3.png'
import docmind from '../../../images/docmind.mp4'
import { motion as m, useIsPresent } from 'framer-motion'

const SemanticAnalyze = () => {
    const isPresent = useIsPresent();
    const videoRef = useRef(null);
    const [isVideoInView, setIsVideoInView] = useState(false);

    useEffect(() => {
        const scroll = new LocomotiveScroll({
            el: document.querySelector("#root"),
            smooth: true,
            smartphone: {
                smooth: true,
            },
            getDirection: true,
            getSpeed: true,
        });

        // Preload images
        Promise.all([preloadImages("body")]).then(() => {
            setTimeout(() => {
                scroll.update();
            }, 200);
        });

        const handleScroll = () => {
            const video = videoRef.current;
            if (video) {
                const rect = video.getBoundingClientRect();
                const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
                setIsVideoInView(isVisible);
            }
        };

        scroll.on("scroll", handleScroll);

        return () => {
            scroll.destroy();
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;

        if (video && isVideoInView) {
            video.play().catch(error => {
                // Обработка ошибок, если видео не может быть автоматически воспроизведено
                console.error('Video playback error:', error);
            });
        }
    }, [isVideoInView]);

    return (
        <>
            <main>
                <section className={s.section_1}>
                    <div id="section_1_cover" className={s.section_1_cover}>
                        <m.img
                            initial={{ y: "100%" }}
                            animate={{ y: "0%", transition: { duration: 1, ease: "circOut" } }}
                            exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
                            loading="lazy"
                            src={semanalyze} alt="semanalyze" />
                    </div>
                    <div className={s.project_description}>
                        <m.div
                            initial={{ x: "100%" }}
                            animate={{ x: "0%", transition: { duration: 0.5, ease: "circOut" } }}
                            exit={{ x: "-100%", transition: { duration: 0.6, ease: "circIn" } }}
                            className={`${s.description_container} + ${s.project_name}`}>
                            <p className={s.micro_title}>Проект</p>
                            <h1>Семантический анализ текста на основе нейронных сетей</h1>
                        </m.div>
                        <m.div
                            initial={{ x: "100%" }}
                            animate={{ x: "0%", transition: { duration: 0.55, ease: "circOut" } }}
                            exit={{ x: "-100%", transition: { duration: 0.55, ease: "circIn" } }}
                            className={`${s.description_container} + ${s.responsibilities}`}>
                            <p className={s.micro_title}>Обязанности</p>
                            <p className={s.description}>Веб-дизайн, фронтенд, бэкенд</p>
                        </m.div>
                        <m.div
                            initial={{ x: "100%" }}
                            animate={{ x: "0%", transition: { duration: 0.6, ease: "circOut" } }}
                            exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
                            className={`${s.description_container} + ${s.team}`}>
                            <p className={s.micro_title}>Команда</p>
                            <p className={s.description}>Владислав Жужа</p>
                        </m.div>
                        <m.div
                            initial={{ x: "100%" }}
                            animate={{ x: "0%", transition: { duration: 0.5, ease: "circOut" } }}
                            exit={{ x: "-100%", transition: { duration: 0.6, ease: "circIn" } }}
                            className={`${s.description_container} + ${s.summary}`}>
                            <p className={s.micro_title}>Cводка</p>
                            <p className={s.description}>Я разработал систему с помощью которой можно проводить анализ текста, а именно определять тематику, стилистику и тональность текста.</p>
                        </m.div>
                    </div>
                </section>
                <section className={s.section_2} >
                    <div className={s.section_2_video}>
                        <video ref={videoRef} src={docmind} loop muted />
                    </div>
                    <div className={s.section_2_mac}>
                        <img src={macbook} alt="macbook" />
                    </div>
                </section>
                <section className={s.section_3}>
                    <h2>Актуальность</h2>
                    <p className="paragraph">Современный объем текстов, которые мы читаем и пишем, значительно увеличился. Разработанная система позволяет быстро и точно обработать большие объемы текста, что может быть полезно для многих пользователей, включая журналистов, маркетологов, исследователей и других.</p>
                </section>
                <section className={s.section_4}>
                    <h2>Семантический анализ</h2>
                    <p className="paragraph">
                        Семантический (смысловой) анализ текста – этап в последовательности действий алгоритма
                        автоматического понимания текстов, заключающийся в выделении семантических отношений и формировании
                        семантического представления текстов.
                        <br /><br />
                        В ходе анализа текст проходит через несколько этапов обработки:
                        токенизация для идентификации словоформ, морфологический,
                        синтаксический анализ. Последним этапом идет вторичный
                        семантический анализ (первичный анализ в основном происходит параллельно морфологическому),
                        в ходе которого устанавливаются взаимосвязи между сущностями, происходит извлечение мнений и анализ тональности текста.
                    </p>
                    <img src={steps} alt='steps' loading="lazy" />
                </section>
                <section className={s.section_5}>
                    <img src={screen} alt="screen semanalyze" loading="lazy" className={s.section_5_img} />
                </section>
                <section className={s.section_6}>
                    <h2>СОЗДАН, ЧТОБЫ БЫТЬ ОТЗЫВЧИВЫМ</h2>
                    <p className="paragraph">Для меня было невероятно важно создать сайт, который хорошо выглядел бы на всех устройствах, сохранял бы свой целенаправленный дизайн и следовал структуре сетки, чтобы все было плотно и организованно.</p>
                    <div>
                        <img src={phone1} loading="lazy" alt="mobile version of website 1" data-scroll data-scroll-speed="1"></img>
                        <img src={phone2} loading="lazy" alt="mobile version of website 2"></img>
                        <img src={phone3} loading="lazy" alt="mobile version of website 3" data-scroll data-scroll-speed="-1"></img>
                    </div>
                </section>
                <section className={s.section_7} data-scroll data-scroll-speed="3">
                    <div>
                        <h2>Заключение</h2>
                        <p className="paragraph">Для меня было невероятно важно создать сайт, который хорошо выглядел бы на всех устройствах, сохранял бы свой целенаправленный дизайн и следовал структуре сетки, чтобы все было плотно и организованно.</p>
                        <a href='https://github.com/zhuzhavladislav/DocMind' target={'_blank'} className='github_button'>
                            <svg width="30" height="30" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 0C9.3975 0 0 9.17638 0 20.5059C0 29.5797 6.01125 37.2438 14.3587 39.9608C15.4087 40.1403 15.8025 39.5251 15.8025 38.9868C15.8025 38.4998 15.7763 36.8849 15.7763 35.1676C10.5 36.116 9.135 33.9116 8.715 32.7581C8.47875 32.1686 7.455 30.3487 6.5625 29.8617C5.8275 29.4772 4.7775 28.5288 6.53625 28.5032C8.19 28.4775 9.37125 29.9898 9.765 30.605C11.655 33.7065 14.6738 32.835 15.8813 32.2968C16.065 30.9639 16.6162 30.0667 17.22 29.5541C12.5475 29.0414 7.665 27.2728 7.665 19.4293C7.665 17.1993 8.47875 15.3538 9.8175 13.9184C9.6075 13.4057 8.8725 11.3039 10.0275 8.48431C10.0275 8.48431 11.7863 7.94603 15.8025 10.5862C17.4825 10.1248 19.2675 9.89409 21.0525 9.89409C22.8375 9.89409 24.6225 10.1248 26.3025 10.5862C30.3188 7.9204 32.0775 8.48431 32.0775 8.48431C33.2325 11.3039 32.4975 13.4057 32.2875 13.9184C33.6263 15.3538 34.44 17.1737 34.44 19.4293C34.44 27.2984 29.5313 29.0414 24.8588 29.5541C25.62 30.1949 26.2763 31.4253 26.2763 33.3477C26.2763 36.0903 26.25 38.2947 26.25 38.9868C26.25 39.5251 26.6438 40.1659 27.6938 39.9608C31.8628 38.5869 35.4856 35.9707 38.0521 32.4807C40.6185 28.9906 41.9993 24.8025 42 20.5059C42 9.17638 32.6025 0 21 0Z" fill="" />
                            </svg>
                            <p>Исходный код на GitHub</p>
                        </a>
                    </div>
                </section>
            </main>
            <m.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </>
    );
};

export default SemanticAnalyze;
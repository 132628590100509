import React from 'react'
import s from './Table.module.css'
import { Link } from 'react-router-dom'
import { isExternalLink } from '../isExternalLink'
import { motion as m } from 'framer-motion'


const Table = ({ data }) => {
    return (
        <>
            {data && data.length > 0 ?
                <>
                    <section className={`${s.section} ${s.table} ${s.table_fade} ${s.visible}`}>
                        <div
                            className={`${s.head}`}>
                            <div className={`${s.flex_col}`}>
                                <h5>Название</h5>
                            </div>
                            <div className={`${s.flex_col}`}>
                                <h5>Клиент</h5>
                            </div>
                            <div className={`${s.flex_col}`}>
                                <h5>Местоположение</h5>
                            </div>
                            <div className={`${s.flex_col}`}>
                                <h5>Услуги</h5>
                            </div>
                            <div className={`${s.flex_col}`}>
                                <h5>Год</h5>
                            </div>
                        </div>
                        <ul className={`${s.items}`}>
                            {data?.map((item, index) => (
                                <m.div
                                    key={item.id}
                                    style={{ overflow: "hidden" }}
                                >
                                    <m.li
                                        initial={{ y: "-100%" }}
                                        animate={{ y: "0%", transition: { duration: 0.3, ease: "circOut", delay: 0.1 + index * 0.3 } }}
                                        exit={{ x: "-100%", transition: { duration: 0.3, ease: "circIn", delay: index * 0.1 } }}
                                        className={`${s.visible}`}>
                                        <m.div
                                            initial={{ x: "-100%" }}
                                            animate={{ x: "0%", transition: { duration: 0.3, ease: "circOut", delay: 1 + index * 0.3 } }}
                                            className={s.stripe}></m.div>
                                        {isExternalLink(item.url) ?
                                            <a href={item.url} className={s.row} target="_blank" rel="noopener noreferrer">
                                                <div className={`${s.flex_col}`}>
                                                    <h4 dangerouslySetInnerHTML={{ __html: item.name }}></h4>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <p>{item.client}</p>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <p>{item.location}</p>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <p>{item.services}</p>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <p>{item.year}</p>
                                                </div>
                                            </a>
                                            : item.url ?
                                                <Link to={item.url} className={s.row}>
                                                    <div className={`${s.flex_col}`}>
                                                        <h4 dangerouslySetInnerHTML={{ __html: item.name }}></h4>
                                                    </div>
                                                    <div className={`${s.flex_col}`}>
                                                        <p>{item.client}</p>
                                                    </div>
                                                    <div className={`${s.flex_col}`}>
                                                        <p>{item.location}</p>
                                                    </div>
                                                    <div className={`${s.flex_col}`}>
                                                        <p>{item.services}</p>
                                                    </div>
                                                    <div className={`${s.flex_col}`}>
                                                        <p>{item.year}</p>
                                                    </div>
                                                </Link>
                                                :
                                                <div style={{ cursor: "not-allowed" }} className={s.row}>
                                                    <div className={`${s.flex_col}`}>
                                                        <h4 dangerouslySetInnerHTML={{ __html: item.name }}></h4>
                                                    </div>
                                                    <div className={`${s.flex_col}`}>
                                                        <p>{item.client}</p>
                                                    </div>
                                                    <div className={`${s.flex_col}`}>
                                                        <p>{item.location}</p>
                                                    </div>
                                                    <div className={`${s.flex_col}`}>
                                                        <p>{item.services}</p>
                                                    </div>
                                                    <div className={`${s.flex_col}`}>
                                                        <p>{item.year}</p>
                                                    </div>
                                                </div>
                                        }
                                    </m.li>
                                </m.div>
                            ))}
                        </ul>
                    </section>
                    <section className={`${s.section} ${s.grid} ${s.grid_fade}`}>
                        <ul>
                            {data?.map(item => (
                                <m.li
                                    initial="hidden"
                                    key={item.id}
                                    whileInView="visible"
                                    exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.6, ease: "circOut" }}
                                    variants={{
                                        visible: { opacity: 1, scale: 1 },
                                        hidden: { opacity: 0, scale: 0.5 }
                                    }}
                                    className={`${s.visible}`}>
                                    {isExternalLink(item.url) ?
                                        <a href={item.url} className={`${s.a} ${s.row}`} target="_blank" rel="noopener noreferrer">
                                            <div className={`${s.flex_col} ${s.tile_image}`}>
                                                <div className={`${s.overlay} ${s.overlay_image}`} style={{ display: "flex", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundImage: `url(${item.preview})`, alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                                    {!item.preview ? <p>Временно отсутствует</p> : null}
                                                </div>
                                            </div>
                                            <div className={`${s.flex_col}`}>
                                                <h4><span>{item.name}</span></h4>
                                                <div className={s.stripe}></div>
                                            </div>
                                            <div className={`${s.flex_col}`}>
                                                <p>{item.services}</p>
                                            </div>
                                            <div className={`${s.flex_col}`}>
                                                <p>{item.year}</p>
                                            </div>
                                        </a>
                                        : item.url ?
                                            <Link to={item.url} className={`${s.a} ${s.row}`}>
                                                <div className={`${s.flex_col} ${s.tile_image}`}>
                                                    <div className={`${s.overlay} ${s.overlay_image}`} style={{ display: "flex", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundImage: `url(${item.preview})`, alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                                        {!item.preview ? <p>Временно отсутствует</p> : null}
                                                    </div>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <h4><span>{item.name}</span></h4>
                                                    <div className={s.stripe}></div>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <p>{item.services}</p>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <p>{item.year}</p>
                                                </div>
                                            </Link>
                                            :
                                            <div style={{ cursor: "not-allowed" }} className={`${s.a} ${s.row}`}>
                                                <div className={`${s.flex_col} ${s.tile_image}`}>
                                                    <div className={`${s.overlay} ${s.overlay_image}`} style={{ display: "flex", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundImage: `url(${item.preview})`, alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                                        {!item.preview ? <p>Временно отсутствует</p> : null}
                                                    </div>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <h4><span>{item.name}</span></h4>
                                                    <div className={s.stripe}></div>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <p>{item.services}</p>
                                                </div>
                                                <div className={`${s.flex_col}`}>
                                                    <p>{item.year}</p>
                                                </div>
                                            </div>
                                    }
                                </m.li>
                            ))}
                        </ul>
                    </section>
                </>
                :
                <p className='paragraph'>Пока пусто 😶</p>
            }
        </>
    )
}

export default Table
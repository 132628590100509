import React, { useEffect, useRef } from "react";
import s from './PreLoader.module.css'
import gsap from "gsap";

const PreLoader = () => {
    const textsRef = useRef(null);

    useEffect(() => {
        const texts = textsRef.current.querySelectorAll(`.${s.text}`);
        const tl = gsap.timeline();

        tl.set(texts, { opacity: 0 });

        texts.forEach((text, index) => {
            if (index === 0) {
                tl.to(text, {
                    delay: 1,
                    duration: 0.3,
                    opacity: 1,
                    ease: "Power3.easeOut",
                })
                    .to(text, {
                        duration: 0.3,
                        opacity: 0,
                        ease: "Power3.easeOut",
                    });
            } else if (index === texts.length - 1) {
                tl.to(text, {
                    duration: 0.1,
                    opacity: 1,
                    ease: "Power3.easeOut",
                })
                    .to(text, {
                        duration: 1,
                        opacity: 1,
                        ease: "Power3.easeOut",
                    });
            } else {
                tl.to(text, {
                    duration: 0.1,
                    opacity: 1,
                    ease: "Power3.easeOut",
                })
                    .to(text, {
                        duration: 0.15,
                        opacity: 1,
                        css: { display: "none" },
                        ease: "Power3.easeOut",
                    });
            }
        });

        tl.to(`.${s.preloader}`, {
            duration: 1,
        })
            .to(
                `.${s.preloader}`,
                {
                    duration: 1.5,
                    height: "0vh",
                    ease: "Power3.easeOut",
                },
                "-=2"
            )
            .to(`.${s.preloader}`, {
                duration: 0,
                css: { display: "none" },
            });
        // Store a flag indicating the first visit
        sessionStorage.setItem("firstVisit", "false");
    }, []);

    // Check if it's the first visit
    const isFirstVisit = sessionStorage.getItem("firstVisit") !== "false";


    return (
        <div className={s.preloader} style={isFirstVisit ? null : { display: "none" }} data-scroll data-scroll-sticky data-scroll-target="#root">
            <div className={s.texts_container} ref={textsRef}>
                <span><p className={s.text}>Привет</p></span>
                <span><p className={s.text}>Hello</p></span>
                <span><p className={s.text}>Hola</p></span>
                <span><p className={s.text}>Bonjour</p></span>
                <span><p className={s.text}>Ciao</p></span>
                <span><p className={s.text}>Guten tag</p></span>
                <span><p className={s.text}>स्वागत हे</p></span>
                <span><p className={s.text}>おい</p></span>
                <span><p className={s.text}>Hallo</p></span>
            </div>
        </div>
    );
};

export default PreLoader;

import semanalyze_cover from "../images/semanalyze.jpg"
import semanalyze_preview from "../images/semanalyze2.jpg"

export const data = [
    {
        id: 1,
        name: "Семантический анализ текста на основе нейронных сетей",
        description: "Анализ текста, а именно определение тематики, стилистику и тональности текста.",
        client: "Личный проект",
        location: "Россия",
        services: "Разработка",
        year: "2023",
        cover: semanalyze_cover,
        preview: semanalyze_preview,
        url: "/semantic-analyze",
        important: true,
        archive: false,
    },
    {
        id: 2,
        name: "Кулинарные Шедевры в Дизайне: Кондитерский дом Куликовский",
        description: "Добро пожаловать в волшебный мир Куликовского Кондитерского Дома, где искусство кондитерства сочетается с вдохновляющим дизайном!",
        client: "Kulikov",
        location: "Казахстан",
        services: "Дизайн",
        year: "c 2019",
        cover: null,
        preview: null,
        url: "",
        important: false,
        archive: false,
    },
    {
        id: 3,
        name: "RASSLABON: Новое измерение удовольствия",
        description: "",
        client: "RASSLABON (Личный проект)",
        location: "Казахстан",
        services: "Дизайн",
        year: "2021",
        cover: null,
        preview: null,
        url: "",
        important: false,
        archive: false,
    },
    {
        id: 4,
        name: "Отличительная игровая айдентика для EzPlaystation",
        description: "",
        client: "EzPlaystation",
        location: "Казахстан",
        services: "Дизайн",
        year: "2020",
        cover: null,
        preview: null,
        url: "",
        important: false,
        archive: false,
    },
    {
        id: 5,
        name: "Sneakerman",
        description: "",
        client: "Sneakerman",
        location: "Россия",
        services: "Дизайн",
        year: "2019",
        cover: null,
        preview: null,
        url: "",
        important: false,
        archive: false,
    }
]
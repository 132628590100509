import React from 'react'
import { useRoutes } from 'react-router-dom'
import {publicRoutes} from './Routes'
import { AnimatePresence } from 'framer-motion'

const AppRouter = () => {
  const element = useRoutes(publicRoutes)

  return (
    <AnimatePresence mode="wait" initial={false}>
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  )
}

export default AppRouter
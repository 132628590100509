import { BrowserRouter } from 'react-router-dom';
import './App.css'
import AppRouter from './Router/AppRouter';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import 'locomotive-scroll/src/locomotive-scroll.scss'

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <AppRouter />
      <Footer />
    </BrowserRouter>
  );
};

export default App;

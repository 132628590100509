import React, { useEffect, useState } from 'react'
import s from './Footer.module.css'
import { Link } from 'react-router-dom'
import me from '../../images/me2.png'

const Footer = () => {
    const [currentTime, setCurrentTime] = useState()

    const getGMT = () => {
        const date = new Date();
        const gmtOffset = 4;
        const gmtSign = gmtOffset >= 0 ? '+' : '-';
        const gmtFormatted = `GMT${gmtSign}${Math.abs(gmtOffset).toString()}`;

        const hours = date.getUTCHours() + gmtOffset;
        const minutes = date.getUTCMinutes();

        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        const time = `${formattedHours}:${formattedMinutes} ${amOrPm} ${gmtFormatted}`;
        setCurrentTime(time)
    }

    useEffect(() => {
        let timerId = setTimeout(function tick() {
            getGMT()
            timerId = setTimeout(tick, 60000);
        }, 0);
    }, [])

    return (
        <footer>
            <div className={s.footer}>
                <div className={s.first_column}>
                    <div className={s.first_column_title}>
                        <img src={me} alt="Avatar of Vladislav Zhuzha" />
                        <p>ДАВАЙТЕ РАБОТАТЬ ВМЕСТЕ</p>
                    </div>
                    <div className={s.first_column_buttons}>
                        <a href='mailto:contact@zhuzhavladislav.ru' className={s.button}><p>contact@zhuzhavladislav.ru</p></a>
                        <a href='tel:+79966237199' className={s.button}><p>+7 996 623-71-99</p></a>
                    </div>
                </div>
                <div
                    className={s.stripe}></div>
                <div className={s.second_column}>
                    <p>Работы</p>
                    <div>
                        <Link to='/works/design'>Графический дизайн</Link>
                        <Link to='/works/development'>Разработка</Link>
                        <Link to='/works/archive'>Архив</Link>
                    </div>
                </div>
                <div className={s.second_column}>
                    <p>Cоциальные сети</p>
                    <div>
                        <a href='https://github.com/zhuzhavladislav' target="_blank">github</a>
                        <a href='https://www.figma.com/@zhuzhavladislav' target="_blank">figma</a>
                        <a href='https://t.me/zhuzha_vladislav' target="_blank">telegram</a>
                        <a href='https://instagram.com/zhuzhastudio' target="_blank">instagram</a>
                    </div>
                </div>
            </div>
            <p className={s.time}>ЛОКАЛЬНОЕ ВРЕМЯ: {currentTime}</p>
            <p className={s.copyright}>©2023 Vladislav Zhuzha. All Rights Reserved.</p>
        </footer>
    )
}

export default Footer
import React from 'react'
import s from './ProjectsCard.module.css'
import { Link } from 'react-router-dom'
import { isExternalLink } from '../isExternalLink'
import {motion as m} from 'framer-motion'

const ProjectsCard = ({ data }) => {
    return (
        <m.div 
            initial="hidden"
            whileInView="visible"
            exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, ease: "circOut" }}
            variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0, scale: 0.5 }
            }}>
            {isExternalLink(data.url) ?
                <a href={data.url} target="_blank" rel="noopener noreferrer">
                    <div className={s.card}>
                        <img src={data.cover} alt={data.name} className={s.cardBackground}></img>
                        <div className={s.column}>
                            <div>{data.services} • {data.year}</div>
                            <h2>{data.name}</h2>
                            <p>{data.description}</p>
                            <button>Посмотреть кейс
                                <svg className='svg' width="25" height="25" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M284.752 188.144V238.967H524.505L169.847 593.626L205.202 628.981L559.861 274.323V514.076H610.684V213.556L610.132 188.697L585.272 188.144H284.752Z" fill="" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </a>
                : data.url ?
                    <Link to={data.url}>
                        <div className={s.card}>
                            <img src={data.cover} alt={data.name} className={s.cardBackground} loading="lazy"></img>
                            <div className={s.column}>
                                <div>{data.services} • {data.year}</div>
                                <h2>{data.name}</h2>
                                <p>{data.description}</p>
                                <button>Посмотреть кейс
                                    <svg className='svg' fill="" width="25px" height="25px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M 18.71875 6.78125 L 17.28125 8.21875 L 24.0625 15 L 4 15 L 4 17 L 24.0625 17 L 17.28125 23.78125 L 18.71875 25.21875 L 27.21875 16.71875 L 27.90625 16 L 27.21875 15.28125 Z" /></svg>
                                </button>
                            </div>
                        </div>
                    </Link>
                    :
                    <div className={s.card} style={{ cursor: "not-allowed" }}>
                        <img src={data.cover} alt={data.name} className={s.cardBackground}></img>
                        <div className={s.column}>
                            <div>{data.services} • {data.year}</div>
                            <h2>{data.name}</h2>
                            <p>{data.description}</p>
                            <button className={s.button_not_ready}>Кейс ещё не готов</button>
                        </div>
                    </div>
            }
        </m.div>
    )
}

export default ProjectsCard
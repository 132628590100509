import ProjectsCard from '../../components/ProjectsCard/ProjectsCard';
import me from '../../images/me1.jpg'
import s from './Main.module.css'
import { useEffect, useRef, useState } from 'react';
import LocomotiveScroll from 'locomotive-scroll'
import PreLoader from '../../components/PreLoader/PreLoader';
import { preloadImages } from '../../components/preloadImages';
import { data } from "../../data/data"
import { motion as m, useIsPresent } from 'framer-motion'

const Main = () => {
    const isPresent = useIsPresent();

    useEffect(() => {
        const scroll = new LocomotiveScroll({
            el: document.querySelector("#root"),
            smooth: true,
            smartphone: {
                smooth: true,
            },
            getDirection: true,
            getSpeed: true,
            // lerp: 0.1
        })

        // Preload images
        Promise.all([preloadImages("body")]).then(() => {
            setTimeout(() => {
                scroll.update();
            }, 200);
        });
        return () => {
            scroll.destroy()
        };
    }, [])

    return (
        <>
            <m.main>
                <PreLoader />
                <div className={s.section_1_background}></div>
                <section className={s.section_1}>
                    <m.img
                        initial={{ x: "20%" }}
                        animate={{ x: "0%", transition: { duration: 0.3, ease: "circOut" } }}
                        exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-position="top"
                        loading="lazy"
                        id='VZ' src={me}
                        alt="Vladislav Zhuzha"
                        className={s.vz}></m.img>
                    <m.h1
                        initial={{ x: "100%" }}
                        animate={{ x: "0%", transition: { duration: 0.5, ease: "circOut" } }}
                        exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
                        data-scroll data-scroll-speed="2"
                        data-scroll-position="top">Влюблен в создание потрясающих работ своими руками.</m.h1>
                    <div
                        data-scroll
                        data-scroll-speed="1.5"
                        data-scroll-position="top">
                        <m.svg
                            initial={{ x: "400%" }}
                            animate={{ x: "0%", transition: { delay: 0.1, duration: 0.5, ease: "circOut" } }}
                            exit={{ x: "-800%", transition: { duration: 0.5, ease: "circIn" } }} className={s.line} width="79" height="1" viewBox="0 0 79 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line y1="0.5" x2="79" y2="0.5" stroke="" />
                        </m.svg>
                    </div>

                    <m.p
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-position="top"
                        initial={{ x: "200%" }}
                        animate={{ x: "0%", transition: { duration: 0.6, ease: "circOut" } }}
                        exit={{ x: "-200%", transition: { duration: 0.5, ease: "circIn" } }}
                    >Меня зовут Владислав Жужа. Я занимаюсь
                        дизайном, веб-разработкой и на данный момент
                        учусь в <a href="https://ssau.ru/" target={'_blank'} className={s.SSAU}>SSAU</a>.</m.p>
                    <m.div className={s.social_media}
                        data-scroll
                        data-scroll-speed="0.5"
                        data-scroll-position="top"
                        initial={{ opacity: 0, y: "-100%" }}
                        animate={{ opacity: 1, y: "0%", transition: { delay: 0.6, duration: 0.5, ease: "circOut" } }}
                        exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}>
                        <a
                            href="https://github.com/zhuzhavladislav" target={'_blank'}>
                            <svg width="30" height="30" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 0C9.3975 0 0 9.17638 0 20.5059C0 29.5797 6.01125 37.2438 14.3587 39.9608C15.4087 40.1403 15.8025 39.5251 15.8025 38.9868C15.8025 38.4998 15.7763 36.8849 15.7763 35.1676C10.5 36.116 9.135 33.9116 8.715 32.7581C8.47875 32.1686 7.455 30.3487 6.5625 29.8617C5.8275 29.4772 4.7775 28.5288 6.53625 28.5032C8.19 28.4775 9.37125 29.9898 9.765 30.605C11.655 33.7065 14.6738 32.835 15.8813 32.2968C16.065 30.9639 16.6162 30.0667 17.22 29.5541C12.5475 29.0414 7.665 27.2728 7.665 19.4293C7.665 17.1993 8.47875 15.3538 9.8175 13.9184C9.6075 13.4057 8.8725 11.3039 10.0275 8.48431C10.0275 8.48431 11.7863 7.94603 15.8025 10.5862C17.4825 10.1248 19.2675 9.89409 21.0525 9.89409C22.8375 9.89409 24.6225 10.1248 26.3025 10.5862C30.3188 7.9204 32.0775 8.48431 32.0775 8.48431C33.2325 11.3039 32.4975 13.4057 32.2875 13.9184C33.6263 15.3538 34.44 17.1737 34.44 19.4293C34.44 27.2984 29.5313 29.0414 24.8588 29.5541C25.62 30.1949 26.2763 31.4253 26.2763 33.3477C26.2763 36.0903 26.25 38.2947 26.25 38.9868C26.25 39.5251 26.6438 40.1659 27.6938 39.9608C31.8628 38.5869 35.4856 35.9707 38.0521 32.4807C40.6185 28.9906 41.9993 24.8025 42 20.5059C42 9.17638 32.6025 0 21 0Z" fill="" />
                            </svg>
                        </a>
                        <a
                            initial={{ opacity: 0, y: "-100%" }}
                            animate={{ opacity: 1, y: "0%", transition: { delay: 0.8, duration: 0.5, ease: "circOut" } }}
                            exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
                            href="https://www.figma.com/@zhuzhavladislav" target={'_blank'}>
                            <svg width="30" height="30" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 20C14 18.2319 14.7375 16.5362 16.0503 15.286C17.363 14.0357 19.1435 13.3333 21 13.3333C22.8565 13.3333 24.637 14.0357 25.9497 15.286C27.2625 16.5362 28 18.2319 28 20C28 21.7681 27.2625 23.4638 25.9497 24.714C24.637 25.9643 22.8565 26.6667 21 26.6667C19.1435 26.6667 17.363 25.9643 16.0503 24.714C14.7375 23.4638 14 21.7681 14 20ZM0 33.3333C0 31.5652 0.737498 29.8695 2.05025 28.6193C3.36301 27.369 5.14348 26.6667 7 26.6667H14V33.3333C14 35.1014 13.2625 36.7971 11.9497 38.0474C10.637 39.2976 8.85652 40 7 40C5.14348 40 3.36301 39.2976 2.05025 38.0474C0.737498 36.7971 0 35.1014 0 33.3333ZM14 0V13.3333H21C22.8565 13.3333 24.637 12.631 25.9497 11.3807C27.2625 10.1305 28 8.43478 28 6.66667C28 4.89856 27.2625 3.20286 25.9497 1.95262C24.637 0.702379 22.8565 0 21 0L14 0ZM0 6.66667C0 8.43478 0.737498 10.1305 2.05025 11.3807C3.36301 12.631 5.14348 13.3333 7 13.3333H14V0H7C5.14348 0 3.36301 0.702379 2.05025 1.95262C0.737498 3.20286 0 4.89856 0 6.66667V6.66667ZM0 20C0 21.7681 0.737498 23.4638 2.05025 24.714C3.36301 25.9643 5.14348 26.6667 7 26.6667H14V13.3333H7C5.14348 13.3333 3.36301 14.0357 2.05025 15.286C0.737498 16.5362 0 18.2319 0 20V20Z" fill="" />
                            </svg>
                        </a>
                        <a
                            initial={{ opacity: 0, y: "-100%" }}
                            animate={{ opacity: 1, y: "0%", transition: { delay: 1, duration: 0.5, ease: "circOut" } }}
                            exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
                            href="https://t.me/zhuzha_vladislav" target={'_blank'}>
                            <svg width="30" height="30" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M46 23C46 29.1 43.5768 34.9501 39.2635 39.2635C34.9501 43.5768 29.1 46 23 46C16.9 46 11.0499 43.5768 6.73654 39.2635C2.42321 34.9501 0 29.1 0 23C0 16.9 2.42321 11.0499 6.73654 6.73654C11.0499 2.42321 16.9 0 23 0C29.1 0 34.9501 2.42321 39.2635 6.73654C43.5768 11.0499 46 16.9 46 23V23ZM23.8251 16.9798C21.5884 17.9113 17.1149 19.8375 10.4104 22.7585C9.32363 23.1897 8.7515 23.6152 8.69975 24.0292C8.6135 24.7279 9.49038 25.0039 10.6835 25.3805L11.1866 25.5386C12.3596 25.921 13.9409 26.3666 14.7602 26.3839C15.5077 26.4011 16.3386 26.0964 17.2557 25.4639C23.5204 21.2348 26.7548 19.0986 26.956 19.0526C27.0998 19.0181 27.301 18.9779 27.4332 19.0986C27.5684 19.2165 27.554 19.4436 27.5396 19.504C27.4534 19.8749 24.012 23.0719 22.2324 24.7279C21.6775 25.2454 21.2836 25.6105 21.2031 25.6939C21.0259 25.875 20.8457 26.0533 20.6626 26.2286C19.5701 27.2809 18.7536 28.0686 20.7058 29.3566C21.6459 29.9776 22.3991 30.4865 23.1495 30.9982C23.966 31.556 24.7825 32.1109 25.8405 32.8066C26.1079 32.9791 26.3666 33.166 26.6168 33.3442C27.5684 34.0227 28.428 34.6323 29.4831 34.5345C30.0984 34.477 30.7338 33.902 31.0558 32.177C31.8176 28.1031 33.3155 19.2797 33.6605 15.6429C33.6816 15.3405 33.669 15.0368 33.6231 14.7372C33.5961 14.4955 33.4791 14.2728 33.2954 14.1134C33.0338 13.9328 32.7219 13.8392 32.4041 13.846C31.5416 13.8604 30.2105 14.3233 23.8251 16.9798V16.9798Z" fill="" />
                            </svg>
                        </a>
                    </m.div>
                </section>
                <m.div
                    exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
                    className={s.bridge} data-scroll data-scroll-speed="-1" data-scroll-position="top">
                    <m.h5
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        variants={{
                            visible: { opacity: 1, y: "0%" },
                            hidden: { opacity: 0, y: "100%" }
                        }}
                    >Важные работы</m.h5>
                    <m.svg
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{ delay: 1, duration: 0.3, ease: "circOut" }}
                        variants={{
                            visible: { opacity: 1, rotate: (0), y: "0px" },
                            hidden: { rotate: (90), y: "-40px" }
                        }}
                        width="1" height="74" viewBox="0 0 1 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="1.2281e-09" x2="0.499942" y2="74" stroke="" />
                    </m.svg>
                </m.div>
                <section className={s.section_3}
                    data-scroll
                    data-scroll-speed="1">
                    {data.filter(data => data.important === true).map(project => <ProjectsCard key={project.id} data={project} />)}
                </section>
            </m.main>
            <m.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </>
    )
}
export default Main;
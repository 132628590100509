import React, { useEffect, useState } from 'react'
import s from './Works.module.css'
import LocomotiveScroll from 'locomotive-scroll'
import { preloadImages } from '../../components/preloadImages';
import Table from '../../components/Table/Table';
import { data } from '../../data/data'
import { Navigate, useParams } from 'react-router-dom';
import { motion as m, useIsPresent } from "framer-motion"

const Works = () => {
    const { category } = useParams()
    const [currentData, setCurrentData] = useState()
    const isPresent = useIsPresent();


    useEffect(() => {
        if (category === "development") {
            setCurrentData(data?.filter(items => items.services.toLowerCase().includes("разработка") && items.archive === false))
        } else if (category === "design") {
            setCurrentData(data?.filter(items => items.services.toLowerCase().includes("дизайн") && items.archive === false))
        } else if (category === "archive") {
            setCurrentData(data?.filter(items => items.archive === true))
        }
    }, [category])

    useEffect(() => {
        const scroll = new LocomotiveScroll({
            el: document.querySelector("#root"),
            smooth: true,
            smartphone: {
                smooth: true,
            },
            getDirection: true,
            getSpeed: true,
            // lerp: 0.1
        })

        // Preload images
        Promise.all([preloadImages("body")]).then(() => {
            setTimeout(() => {
                scroll.update();
            }, 200);
        });
        return () => {
            scroll.destroy()
        };
    }, [])

    return (
        <>
            <m.main
                initial={{ x: "20%" }}
                animate={{ x: "0%", transition: { duration: 0.3, ease: "circOut" } }}
                exit={{ x: "-100%", transition: { duration: 0.5, ease: "circIn" } }}
            >
                {category === "development" ?
                    <>
                        <h1 className={s.h1}>Разработка</h1>
                    </>
                    : category === "design" ?
                        <>
                            <h1 className={s.h1}>Графический дизайн</h1>
                        </>
                        : category === "archive" ?
                            <>
                                <h1 className={s.h1}>Архив</h1>
                            </>
                            : <Navigate to="/" />
                }
                <Table data={currentData} />
            </m.main>
            <m.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </>
    )
}

export default Works
import imagesLoaded from "imagesloaded";

export const preloadImages = (selector) => {
    return new Promise((resolve) => {
        imagesLoaded(
            document.querySelector(selector),
            { background: true },
            resolve
        );
    });
};
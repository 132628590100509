import { Navigate } from "react-router-dom";
import About from "../pages/About/About";
import Main from "../pages/Main/Main";
import SemanticAnalyze from "../pages/Works/SemanticAnalyze/SemanticAnalyze";
import Works from "../pages/Works/Works";

export const publicRoutes = [
    {
        path: "/",
        element: <Main />
    },
    {
        path: "/semantic-analyze",
        element: <SemanticAnalyze />
    },
    {
        path: "/about",
        element: <About />
    },
    {
        path: `/works/:category`,
        element: <Works />
    },
    {
        path: '*',
        element: <Navigate to="/" />
    }
]
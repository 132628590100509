import React from 'react'
import s from './Header.module.css'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <header>
            <Link to="/">
                <svg id="zhuzha" className={s.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.5 158.89">
                    <path d="M39.61,107.66V50.89s0-1.13,0-1.13c0-1.35-.69-2.63-1.85-3.31-1.72-1.01-2.99-1.26-4.21-1.17C15.01,45.52,0,60.68,0,79.27c0,18.75,15.25,34,34,34,3.1,0,5.61-2.51,5.61-5.61Zm-28.38-28.38c0-10.62,7.31-19.57,17.16-22.07v44.15c-9.85-2.51-17.16-11.45-17.16-22.07Z" />
                    <path d="M83.27,4.69v27.43s-28.15,0-28.15,0L33.3,10.3c-.89-.89-2.15-1.37-3.4-1.19-5.7,.82-6.99,6.66-3.89,9.77l21.18,21.18V118.5s-21.18,21.18-21.18,21.18c-3.11,3.11-1.81,8.95,3.89,9.77,1.25,.18,2.51-.3,3.4-1.19l21.83-21.83h28.15v27.76c0,1.26,.55,2.49,1.57,3.25,4.61,3.44,9.66,.23,9.66-4.16v-26.84c9.54-.03,18.42-2.91,25.84-7.83l26.4,26.4c2.19,2.19,5.74,2.19,7.94,0,1.1-1.1,1.64-2.53,1.64-3.97,0-1.44-.55-2.87-1.64-3.97l-25.18-24.86c8-5,37-19,37-33s-27-27-36-33l24.18-24.38c1.1-1.1,1.64-2.53,1.64-3.97,0-2.63-1.83-5.26-5.5-5.79-1.1-.16-2.65,.4-3.44,1.18l-26.85,26.84c-7.46-5-16.41-7.93-26.04-7.96V5.61c0-4.39-5.04-7.6-9.66-4.16-1.01,.76-1.57,1.98-1.57,3.25Zm11.07,110.52H58.41v-30.32s92.84,.3,92.84,.3c1.38,0,1.86,1.84,.66,2.52-19.75,11.15-40.96,27.5-57.57,27.5Zm56.95-41.54H58.41v-30.32h35.93c16.8,0,34.8,14.85,57.61,27.8,1.2,.68,.72,2.51-.66,2.51Z" />
                </svg>
            </Link>
            <nav>
                <Link to='/works/design'>ГРАФИЧЕСКИЙ ДИЗАЙН</Link>
                <Link to='/works/development'>РАЗРАБОТКА</Link>
                <Link to='/about'>ОБО МНЕ</Link>
                <Link to='/works/archive'>АРХИВ</Link>
            </nav>
        </header>
    )
}

export default Header